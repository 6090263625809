
export const images = [
    { thumb: require("../images/_thumbnails/00.jpg").default, full: require("../images/_resized/00.jpg").default, text: require("../contents/album/00.html").default, nome: "Presentazione", cognome: "", dark: true, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/01.jpg").default, full: require("../images/_resized/01.jpg").default, text: require("../contents/album/01.html").default, nome: "Sabatino", cognome: "Lopez", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/02.jpg").default, full: require("../images/_resized/02.jpg").default, text: require("../contents/album/02.html").default, nome: "B.", cognome: "G.", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/03.jpg").default, full: require("../images/_resized/03.jpg").default, text: require("../contents/album/03.html").default, nome: "Anton Giulio", cognome: "Barrili", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/04.jpg").default, full: require("../images/_resized/04.jpg").default, text: require("../contents/album/04.html").default, nome: "Severino", cognome: "Ferrari", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/05.jpg").default, full: require("../images/_resized/05.jpg").default, text: require("../contents/album/05.html").default, nome: "Jack", cognome: "la Bolina", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/06.jpg").default, full: require("../images/_resized/06.jpg").default, text: require("../contents/album/06.html").default, nome: "Angelo", cognome: "Tomaselli", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/07.jpg").default, full: require("../images/_resized/07.jpg").default, text: require("../contents/album/07.html").default, nome: "Dino", cognome: "Mantovani", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/08.jpg").default, full: require("../images/_resized/08.jpg").default, text: require("../contents/album/08.html").default, nome: "Giovanni", cognome: "Duprè", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/09.jpg").default, full: require("../images/_resized/09.jpg").default, text: require("../contents/album/09.html").default, nome: "Giannino Antona", cognome: "Traversi", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/10.jpg").default, full: require("../images/_resized/10.jpg").default, text: require("../contents/album/10.html").default, nome: "Giovanni", cognome: "Marradi", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/11.jpg").default, full: require("../images/_resized/11.jpg").default, text: require("../contents/album/11.html").default, nome: "Marco", cognome: "Praga", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/12.jpg").default, full: require("../images/_resized/12.jpg").default, text: require("../contents/album/12.html").default, nome: "Pietro", cognome: "Mascagni", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/13.jpg").default, full: require("../images/_resized/13.jpg").default, text: require("../contents/album/13.html").default, nome: "Roberto", cognome: "Bracco", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/14.jpg").default, full: require("../images/_resized/14.jpg").default, text: require("../contents/album/14.html").default, nome: "Ettore", cognome: "Cantagalli", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/15.jpg").default, full: require("../images/_resized/15.jpg").default, text: require("../contents/album/15.html").default, nome: "Ermete", cognome: "Novelli", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/16.jpg").default, full: require("../images/_resized/16.jpg").default, text: require("../contents/album/16.html").default, nome: "Irma", cognome: "Gramatica", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/17.jpg").default, full: require("../images/_resized/17.jpg").default, text: require("../contents/album/17.html").default, nome: "Teresa", cognome: "Mariani", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/18.jpg").default, full: require("../images/_resized/18.jpg").default, text: require("../contents/album/18.html").default, nome: "Libero", cognome: "Pilotto", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/19.jpg").default, full: require("../images/_resized/19.jpg").default, text: require("../contents/album/19.html").default, nome: "Emilio", cognome: "Zago", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/20.jpg").default, full: require("../images/_resized/20.jpg").default, text: require("../contents/album/20.html").default, nome: "Giovanni", cognome: "Emanuel", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/21.jpg").default, full: require("../images/_resized/21.jpg").default, text: require("../contents/album/21.html").default, nome: "Edoardo", cognome: "Ferravilla", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/22.jpg").default, full: require("../images/_resized/22.jpg").default, text: require("../contents/album/22.html").default, nome: "Giovanni", cognome: "Pascoli", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/23.jpg").default, full: require("../images/_resized/23.jpg").default, text: require("../contents/album/23.html").default, nome: "Giuseppe", cognome: "Picciola", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/24.jpg").default, full: require("../images/_resized/24.jpg").default, text: require("../contents/album/24.html").default, nome: "Giovanni Alfredo", cognome: "Cesareo", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/25.jpg").default, full: require("../images/_resized/25.jpg").default, text: require("../contents/album/25.html").default, nome: "Eduardo Giacomo", cognome: "Boner", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/26.jpg").default, full: require("../images/_resized/26.jpg").default, text: require("../contents/album/26.html").default, nome: "Augusto", cognome: "Franchetti", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/27.jpg").default, full: require("../images/_resized/27.jpg").default, text: require("../contents/album/27.html").default, nome: "Edoardo", cognome: "Calandra", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/28.jpg").default, full: require("../images/_resized/28.jpg").default, text: require("../contents/album/28.html").default, nome: "Guido", cognome: "Mazzoni", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/29.jpg").default, full: require("../images/_resized/29.jpg").default, text: require("../contents/album/29.html").default, nome: "Guglielmo", cognome: "Capitelli", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/30.jpg").default, full: require("../images/_resized/30.jpg").default, text: require("../contents/album/30.html").default, nome: "Federico", cognome: "de Roberto", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/31.jpg").default, full: require("../images/_resized/31.jpg").default, text: require("../contents/album/31.html").default, nome: "Cesare", cognome: "De Lollis", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/32.jpg").default, full: require("../images/_resized/32.jpg").default, text: require("../contents/album/32.html").default, nome: "Enrico Annibale", cognome: "Butti", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/33.jpg").default, full: require("../images/_resized/33.jpg").default, text: require("../contents/album/33.html").default, nome: "Guglielmo", cognome: "Anastasi", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/34.jpg").default, full: require("../images/_resized/34.jpg").default, text: require("../contents/album/34.html").default, nome: "Luigi Arnaldo", cognome: "Vassallo", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/35.jpg").default, full: require("../images/_resized/35.jpg").default, text: require("../contents/album/35.html").default, nome: "Angelo Maria", cognome: "Sodini", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/36.jpg").default, full: require("../images/_resized/36.jpg").default, text: require("../contents/album/36.html").default, nome: "Pasquale", cognome: "de Luca", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/37.jpg").default, full: require("../images/_resized/37.jpg").default, text: require("../contents/album/37.html").default, nome: "Giacomo", cognome: "Puccini", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/38.jpg").default, full: require("../images/_resized/38.jpg").default, text: require("../contents/album/38.html").default, nome: "Arturo", cognome: "Viligiardi", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/39.jpg").default, full: require("../images/_resized/39.jpg").default, text: require("../contents/album/39.html").default, nome: "Giuseppe", cognome: "Chiarini", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/40.jpg").default, full: require("../images/_resized/40.jpg").default, text: require("../contents/album/40.html").default, nome: "Davide", cognome: "Calandra", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/41.jpg").default, full: require("../images/_resized/41.jpg").default, text: require("../contents/album/41.html").default, nome: "Giosuè", cognome: "Carducci", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/42.jpg").default, full: require("../images/_resized/42.jpg").default, text: require("../contents/album/42.html").default, nome: "Giuseppe", cognome: "Giacosa", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/43.jpg").default, full: require("../images/_resized/43.jpg").default, text: require("../contents/album/43.html").default, nome: "Giovanni", cognome: "Verga", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/44.jpg").default, full: require("../images/_resized/44.jpg").default, text: require("../contents/album/44.html").default, nome: "Ettore", cognome: "Ximenes", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/45.jpg").default, full: require("../images/_resized/45.jpg").default, text: require("../contents/album/45.html").default, nome: "", cognome: "Trilussa", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/46.jpg").default, full: require("../images/_resized/46.jpg").default, text: require("../contents/album/46.html").default, nome: "Cesare", cognome: "Pascarella", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/47.jpg").default, full: require("../images/_resized/47.jpg").default, text: require("../contents/album/47.html").default, nome: "Eleonora", cognome: "Duse", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/48.jpg").default, full: require("../images/_resized/48.jpg").default, text: require("../contents/album/48.html").default, nome: "Ermete", cognome: "Zacconi", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/49.jpg").default, full: require("../images/_resized/49.jpg").default, text: require("../contents/album/49.html").default, nome: "Umberto", cognome: "Giordano", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/50.jpg").default, full: require("../images/_resized/50.jpg").default, text: require("../contents/album/50.html").default, nome: "Pietro", cognome: "Aldi", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/51.jpg").default, full: require("../images/_resized/51.jpg").default, text: require("../contents/album/51.html").default, nome: "Gerolamo", cognome: "Rovetta", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/52.jpg").default, full: require("../images/_resized/52.jpg").default, text: require("../contents/album/52.html").default, nome: "Gian", cognome: "Della Quercia", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/53.jpg").default, full: require("../images/_resized/53.jpg").default, text: require("../contents/album/53.html").default, nome: "Eduardo", cognome: "Dalbono", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/54.jpg").default, full: require("../images/_resized/54.jpg").default, text: require("../contents/album/54.html").default, nome: "Renato", cognome: "Simoni", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/55.jpg").default, full: require("../images/_resized/55.jpg").default, text: require("../contents/album/55.html").default, nome: "Marino", cognome: "Moretti", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/56.jpg").default, full: require("../images/_resized/56.jpg").default, text: require("../contents/album/56.html").default, nome: "Paride", cognome: "Pascucci", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/57.jpg").default, full: require("../images/_resized/57.jpg").default, text: require("../contents/album/57.html").default, nome: "Emma", cognome: "Gramatica", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/58.jpg").default, full: require("../images/_resized/58.jpg").default, text: require("../contents/album/58.html").default, nome: "Alessandro", cognome: "Varaldo", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/59.jpg").default, full: require("../images/_resized/59.jpg").default, text: require("../contents/album/59.html").default, nome: "Giuseppe Antonio", cognome: "Borgese", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/60.jpg").default, full: require("../images/_resized/60.jpg").default, text: require("../contents/album/60.html").default, nome: "Ferdinando", cognome: "Martini", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/61.jpg").default, full: require("../images/_resized/61.jpg").default, text: require("../contents/album/61.html").default, nome: "Michele", cognome: "Cascella", dark: false, fonte: 'stefano' },
    { thumb: require("../images/_thumbnails/62.jpg").default, full: require("../images/_resized/62.jpg").default, text: require("../contents/album/62.html").default, nome: "Lorenzo", cognome: "Ruggi", dark: false, fonte: 'interna' },
    { thumb: require("../images/_thumbnails/63.jpg").default, full: require("../images/_resized/63.jpg").default, text: require("../contents/album/63.html").default, nome: "Grazia", cognome: "Deledda", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/64.jpg").default, full: require("../images/_resized/64.jpg").default, text: require("../contents/album/64.html").default, nome: "Alfredo", cognome: "Panzini", dark: false, fonte: 'meis' },
    { thumb: require("../images/_thumbnails/65.jpg").default, full: require("../images/_resized/65.jpg").default, text: require("../contents/album/65.html").default, nome: "Milly", cognome: "Dandolo", dark: false, fonte: 'meis' }
];
