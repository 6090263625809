import React from "react";
import { Link } from "react-router-dom";
// import { useSelector } from 'react-redux'
import { motion } from "framer-motion";
import { proxy, useSnapshot } from 'valtio'

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };
const state = proxy({ headerColor: 'light' })

function Header() {
	// const headerColor = useSelector(function (state) {
	// 	return state.headerColor.value.payload
	// })
	const snap = useSnapshot(state)
	return (
		<header className={snap.headerColor} style={{ zIndex: 9999 }}>
			<motion.div
				className="menu fullheight"
				initial={{ y: "-100vh" }}

				animate={{
					y: 0,
					transition: { duration: 0.6, ...transition },
				}}
				exit={{ y: "100vh", transition: { duration: 0.6, ...transition } }}>
				<ul>
					<li>
						<Link to='/' onClick={() => { document.querySelector(".menu").classList.remove("showMenu"); state.headerColor = 'light' }}>
							HOME PAGE
						</Link>
					</li>
					<li>
						<Link to='/presentazione' onClick={() => { document.querySelector(".menu").classList.remove("showMenu"); state.headerColor = 'light' }}>
							PRESENTAZIONE
						</Link>
					</li>
					<li>
						<Link to='/visita' onClick={() => { document.querySelector(".menu").classList.remove("showMenu"); state.headerColor = 'light' }}>
							VISITA VIRTUALE
						</Link>
					</li>
					<li >
						<Link to="/mostra" onClick={() => { document.querySelector(".menu").classList.remove("showMenu"); }}>
							LA MOSTRA
						</Link>
					</li>
					<li>
						<Link to='/lopez' onClick={() => { document.querySelector(".menu").classList.remove("showMenu"); state.headerColor = 'light' }}>
							ALBUM IN ONORE DELLE NOZZE FRA CORRADO LOPEZ E ADA SADUN
						</Link>
					</li>
					<li >
						<Link to="/foto" onClick={() => { document.querySelector(".menu").classList.remove("showMenu"); }}>
							FOTO MATRIMONI
						</Link>
					</li>
					<li >
						<Link to="/culture" onClick={() => { document.querySelector(".menu").classList.remove("showMenu"); }}>
							IL MATRIMONIO NELLE DIVERSE CULTURE
						</Link>
					</li>
					<li>
						<Link to='/colophon' onClick={() => { document.querySelector(".menu").classList.remove("showMenu"); state.headerColor = 'dark' }}>
							COLOPHON
						</Link>
					</li>
					<li>
						<a href='https://www.ibs.it/mazal-tov-matrimonio-ebraico-guida-libro-vari/e/9788874671816' target="_blank" rel="noreferrer">
							CATALOGO
						</a>
					</li>
					<li>
						<span onClick={() => { document.querySelector(".menu").classList.remove("showMenu"); }}>
							CHIUDI
						</span>
					</li>
				</ul>
			</motion.div>
			<div className='container' >
				<div className='row space-between'>
					<div className='logo'>
						<Link to='/'>
							<svg
								id="mazal_tov_logo"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 989 506">

								<g>
									<path className="st0" d="M228.9,116c0-11.5,0.4-28.8,1.2-51.5c0.8-22.8,1.2-40.3,1.2-51.9c0-3.7-2.3-8-8.9-8c-2.9,0-7,0.3-11.6,0.7
		C204.9,5.9,201.5,6,200,6c-2.4,0-6.6-0.1-12.8-0.4c-5-0.3-9.6-0.4-13-0.4c-5.8,0-8.6,6.3-9.5,8.3c-21,45-34.5,73.6-43.8,92.8
		c-1.6,3.2-2.7,5.4-3.5,6.8c-0.7-1.1-1.7-2.7-2.9-5.2l-42.8-94C69.7,9.4,67.4,5,63.2,5C58,5,50.3,5.2,38.9,5.5
		C27.5,5.8,19.9,6,14.8,6c-4.4,0-7.1,0.8-8.9,2.7c-1.3,1.3-1.9,3-1.8,5l0.2,2.4C5.1,33,5.5,44.4,5.4,52.2l0,0.1
		c1,31,1.5,52.4,1.5,63.7c0,10.5-0.5,26.6-1.5,47.8c-1,21.3-1.5,37.5-1.5,48.2c0,2.3,0.5,4.3,1.4,5.7c1.9,3.1,5.9,3.1,7.4,3.1h49
		c4,0,8.6-2.5,8.6-9.6c0-10.7-0.2-25.6-0.7-43.2c-0.3-11.2-0.5-21.2-0.6-29.8c3.9,8.8,8.7,19.8,14.3,33c8.8,21,15.7,36.5,21,47.3
		c1.9,3.6,6.2,5.3,13.1,5.3c4.7,0,5.9,0,6.8-0.2l0.2-0.1c2.8-0.8,5-2.9,6.7-6.5l0.1-0.1c2.6-6.5,5.2-12.9,7.9-19.5
		c4.4-10.9,8.8-21.8,13.2-32.6c4.9-12.2,9-22,12.1-29.3c-0.2,8.1-0.5,18.3-0.9,31c-0.8,21.5-1.1,35.2-1.1,44.6
		c0,7.1,4.7,9.6,8.8,9.6h51.8c5.4,0,8.8-3.4,8.8-9c0-8.2-0.2-23.2-1.4-47.9C229.3,143.7,228.9,127.2,228.9,116z M172.3,166.8
		c0.8-21.2,1.1-35.3,1.1-44.6c0-2.1,0-7.8-4.8-8.9l-1.6-0.4l-1.5,0.8c-2,1.1-3,1.6-21.6,47.6c-4.4,10.9-8.8,21.8-13.2,32.7
		c-2.6,6.5-5.2,12.9-7.8,19.3c-0.3,0.7-0.6,1.2-0.8,1.4c-0.9,0-2.8,0-4.7,0c-3.5,0-4.9-0.5-5.2-0.7c-5.2-10.5-11.9-25.7-20.5-46.4
		c-9.2-21.6-16.1-37.5-20.7-47.1c-0.5-1.2-1.3-2.2-2.5-3.2l-3.6-3.1l-2.9,3.7c-1.3,1.7-1.9,4-1.9,7.1c0,7.5,0.1,21.1,0.7,43.2
		c0.5,17.5,0.7,32.4,0.7,43c0,0.3,0,0.4,0,0.6H12.9c0-10.6,0.5-26.6,1.5-47.6c1-21.4,1.5-37.6,1.5-48.2c0-11.3-0.5-32.8-1.5-63.8
		c0.1-7.9-0.3-19.6-1.1-36.6l0-0.5c0.4,0,0.9-0.1,1.6-0.1c5.2,0,12.9-0.2,24.3-0.5c10.1-0.3,17.5-0.5,22.6-0.5
		c0.4,0.6,1,1.7,1.9,3.6l42.8,94c4,8.5,7.5,12.1,11.7,12.1c3.5,0,5.8-2.8,11-13.5c9.3-19.3,22.8-47.9,43.9-92.9
		c0.9-1.9,1.5-2.7,1.8-3c2.1,0,6.1,0.1,12.1,0.4C193.2,15,197.5,15,200,15c2.9,0,7-0.3,11.6-0.7c5.8-0.6,9.1-0.7,10.7-0.7
		c0,11.5-0.4,28.5-1.2,50.5c-0.8,22.8-1.2,40.3-1.2,51.9c0,11.4,0.4,27.6,1.3,48.1c1.2,24.6,1.4,39.4,1.4,47.5h-51.4
		c0-0.1,0-0.3,0-0.6C171.2,201.8,171.5,188.1,172.3,166.8z"/>
									<path className="st0" d="M334,141.1c3.1,0.2,8.2,0.3,15.3,0.3c8.9,0,13.2-1.4,14.8-4.9l0.1-0.2c0.4-1,0.6-2.3,0.6-3.8
		c0-1.5,0-5.6-6.9-28.1c-4.8-14.9-7.9-23.5-10.1-27.6l-4.1-7.9l-3.9,8c-2.1,4.3-5.3,13-10.6,28.5c-8.2,23.4-8.2,26.9-8.2,28.3
		C321,140.7,330.1,141,334,141.1z M349.3,132.4c-6.9,0-11.8-0.1-14.8-0.3c-2-0.1-3.3-0.2-4.1-0.4c0.6-2.9,2.4-9.3,7.3-23.4
		c2.5-7.3,4.5-13.1,6.2-17.5c1.5,4.2,3.3,9.6,5.5,16.3c5.2,17,6.3,22.8,6.5,24.8C354.7,132.2,352.8,132.4,349.3,132.4z"/>
									<path className="st0" d="M373.3,191.2c0.8,1.5,2.7,6.1,5.8,19.5c1.7,6.9,6.2,9.2,9.8,9.9c4.3,0.9,13.5,1.3,27.9,1.3
		c20.9,0,26.2-0.7,28.5-1.4c6.3-1.8,7-6.2,7-8c0-1.6,0-5-35.3-96.4c-22.6-58.7-37.1-95.3-42-105.9c-2.3-4.9-5.4-7.4-9.2-7.4
		c-2.6,0-6.6,0.2-11.8,0.5c-5.3,0.3-9,0.5-11.4,0.5c-2.9,0-7.2-0.2-12-0.7c-5.1-0.5-9.6-0.7-12.6-0.7c-5.6,0-7.7,6.6-8.4,8.7
		c-2,6.3-14.4,40.2-38.9,106.7c-33.4,90.5-33.4,93.7-33.4,95.3c0,7.4,9.9,8.8,26.9,8.8c25.7,0,32-0.7,34.6-1.4
		c4.5-1.2,7.5-6.2,9.9-16.5c2-8.4,3.3-11.7,3.9-12.9c1.9-0.3,7.5-0.7,23.8-0.7C361.3,190.5,370.3,190.8,373.3,191.2z M264.2,212.9
		c-10.8,0-15.5-0.8-17.4-1.3c1.7-5.8,8.3-25.5,32.4-90.7c24.6-66.6,37-100.6,39.1-107.1c0.4-1.3,0.8-2,1-2.5
		c2.7,0.1,6.4,0.3,10.6,0.7c5.1,0.5,9.6,0.7,12.8,0.7c2.7,0,6.4-0.2,12-0.5c4.8-0.3,8.5-0.5,10.9-0.5c0.2,0.2,0.7,0.8,1.4,2.2
		c4.9,10.4,19.3,46.8,41.8,105.3c27.5,71.3,33.3,88.4,34.5,92.5c0,0-0.1,0-0.2,0c-1.1,0.3-5.9,1-26.1,1c-17,0-23.6-0.6-26.1-1.1
		c-1.1-0.2-2.2-0.7-2.9-3.2c-5-21.4-7.5-24.2-10.1-25.6l-0.2-0.1c-1.5-0.6-3.1-1.4-41.1-1.4c-26.8,0-27.9,1-29.6,2.5
		c-2.1,1.9-4,5.7-6.9,17.9c-1.8,7.6-3.3,9.6-3.7,9.9C294.2,212.3,287.3,212.9,264.2,212.9z"/>
									<path className="st0" d="M454.1,176.7c0,0.7,0,1.4,1.9,9c1.7,12.8,2.6,21.4,2.8,27.2l0.1,0.7l0,0.1c0.5,2.1,2.4,6.9,10.4,6.9
		c8.2,0,20.5,0.1,36.7,0.3c16.2,0.2,28.6,0.3,36.9,0.3c5.7,0,14.4,0.3,25.8,0.9c11.8,0.6,20.4,0.9,26.2,0.9c4.1,0,7.1-2.5,8.1-6.6
		l0-0.2c2.9-15.9,4.4-32.2,4.4-48.5c0-5.6-3.6-7.6-6.6-7.6c-3.1,0-7.8,0.5-14.3,1.6c-7.1,1.1-11.7,1.7-13.8,1.8
		c-9.7,0.7-23.5,1.1-41.2,1.2c3.6-6.2,11.9-19.6,30.8-48.7c21.5-32.9,33.1-50.5,38.9-58.9c2.2-3.4,3.3-6.4,3.3-9.1
		c0-15.4-0.9-26.7-2.7-34.7c-1.6-6.1-6.4-6.8-8.4-6.8c-13.4,0-33.9-0.3-60.7-0.9c-27.4-0.6-47.3-0.9-60.9-0.9
		c-6.3,0-8.5,3.5-8.5,6.8c0,4.3-1.1,11.6-3.2,21.8c-2.3,10.7-3.4,18.1-3.4,22c0,4.8,3,7.9,7.7,7.9c7,0,17.6-0.2,31.3-0.6
		c12.5-0.4,22.3-0.6,29-0.6c-2.7,5.3-10.7,19.2-34.1,55.6C454.1,174.8,454.1,174.8,454.1,176.7z M535,59.7c0-1.1-0.4-6.8-8.3-6.8
		c-6.9,0-17.4,0.2-31.1,0.6c-12.8,0.4-22.8,0.6-29.7,0.6c0.2-2.8,0.9-8.2,3.2-19.1c2-9.4,3.1-16.5,3.3-21.4
		c13.3,0,33.6,0.3,60.3,0.9c26.7,0.6,47,0.9,60.5,0.9c1.7,7.2,2.5,17.9,2.5,32.5c0,0.2-0.1,1.4-1.8,4c-5.8,8.3-17.5,26-39,59
		c-34.4,53.1-34.4,55.1-34.4,56.9c0,0.6,0.2,5.7,7,5.7c19.9,0,35.4-0.4,45.9-1.2c2.3-0.1,7-0.7,14.6-1.9c4.4-0.7,7.9-1.2,10.5-1.3
		c-0.1,15-1.5,30.1-4.1,44.8c-5.7,0-13.9-0.3-25.1-0.9c-11.5-0.6-20.3-0.9-26.2-0.9c-8.2,0-20.5-0.1-36.7-0.3
		c-16.3-0.2-28.7-0.3-36.9-0.3c-0.8,0-1.3-0.1-1.5-0.1c-0.3-6.1-1.2-14.8-2.9-27.4l-0.1-0.4c-0.8-3.3-1.3-5.2-1.5-6.2
		c3.5-5.9,19.8-31.5,35-55.2C535,65.5,535,62,535,59.7z"/>
									<path className="st0" d="M634.7,221.9c25.7,0,32-0.7,34.6-1.4c4.5-1.2,7.5-6.2,9.9-16.5c2-8.4,3.3-11.7,3.9-12.9
		c1.9-0.3,7.5-0.7,23.8-0.7c24.9,0,33.9,0.3,36.9,0.6c0.8,1.5,2.7,6.1,5.8,19.5c1.7,6.9,6.2,9.2,9.8,9.9c4.3,0.9,13.5,1.3,27.9,1.3
		c20.9,0,26.2-0.7,28.5-1.4c6.3-1.8,7-6.2,7-8c0-1.6,0-5-35.3-96.4c-22.6-58.7-37.1-95.3-42-105.9c-2.3-4.9-5.4-7.4-9.2-7.4
		c-2.6,0-6.6,0.2-11.8,0.5c-5.3,0.3-9,0.5-11.4,0.5c-2.9,0-7.2-0.2-12-0.7c-5.1-0.5-9.6-0.7-12.6-0.7c-5.6,0-7.6,6.4-8.4,8.8
		c-2,6.5-16.1,44.8-38.9,106.7c-33.4,90.5-33.4,93.7-33.4,95.3C607.8,220.5,617.7,221.9,634.7,221.9z M735.9,11.8
		c0.2,0.2,0.7,0.8,1.4,2.2c4.9,10.4,19.3,46.8,41.8,105.3c27.5,71.3,33.3,88.4,34.5,92.5c0,0-0.1,0-0.2,0c-1.1,0.3-5.9,1-26.1,1
		c-17,0-23.6-0.6-26.1-1.1c-1.1-0.2-2.2-0.7-2.9-3.2c-5-21.4-7.5-24.2-10.1-25.6l-0.2-0.1c-1.5-0.6-3.1-1.4-41.1-1.4
		c-26.8,0-27.9,1-29.6,2.5c-2.1,1.9-4,5.7-6.9,17.9c-1.8,7.6-3.3,9.6-3.7,9.9c-2,0.4-8.9,1-32.1,1c-10.8,0-15.5-0.8-17.4-1.3
		c1.7-5.8,8.3-25.5,32.4-90.7c25.1-68,37.1-100.7,39.1-107.1c0.4-1.3,0.8-2,1-2.5c2.7,0.1,6.4,0.3,10.6,0.7
		c5.1,0.5,9.6,0.7,12.8,0.7c2.7,0,6.4-0.2,12-0.5C729.8,12,733.5,11.8,735.9,11.8z"/>
									<path className="st0" d="M699.8,105.4c-8.2,23.4-8.2,26.9-8.2,28.3c0,7,9.1,7.3,13,7.4c3.1,0.2,8.2,0.3,15.3,0.3
		c8.9,0,13.2-1.4,14.8-4.9l0.1-0.2c0.4-1,0.6-2.3,0.6-3.8c0-1.5,0-5.6-6.9-28.1c-4.9-14.9-8-23.4-10-27.5l-4.1-8.1l-4,8.2
		C708.3,81.2,705,90,699.8,105.4z M719.9,132.4c-6.9,0-11.8-0.1-14.8-0.3c-2-0.1-3.3-0.2-4.1-0.4c0.6-2.9,2.4-9.3,7.3-23.4
		c2.5-7.3,4.5-13,6.1-17.4c1.5,4.2,3.3,9.5,5.5,16.2c5.2,17,6.3,22.8,6.5,24.8C725.3,132.2,723.4,132.4,719.9,132.4z"/>
									<path className="st0" d="M968,223.2c4.6,0,6.4-0.2,7.8-0.8c2.1-0.7,4.9-2.6,5.9-7.8c2.6-12.5,3.8-26.9,3.8-44.2c0-3.6-2.2-7.9-8.5-7.9
		c-8.5,0-21.1,0.4-39.7,1.4c-18.5,1-31,1.4-39.3,1.4h-1.7l-0.1-1.4c-0.1-5.7-0.1-14.7-0.1-27.2l0.1-38.6c1.6-39,2.9-65.2,4-82.7
		l0.1-1.9l0-0.2c0-5.4-3.6-7.3-6.6-7.3c-3.5,0-8.7,0.2-14.6,0.5C872.4,6.8,867.9,7,864.9,7c-2.3,0-6.5-0.1-12.8-0.4
		c-6.5-0.3-10.8-0.4-13.3-0.4c-6.6,0-8.9,4.1-8.9,7.7c0,12.3,0.3,30,1,51.1c0.7,21.2,1,38.8,1,50.9c0,10.3-0.2,25.3-0.5,47.8
		c-0.3,22.5-0.5,37.5-0.5,48c0,4.6,3.1,8,8.1,8.9l0.4,0c8.2,0.5,14.7,0.7,19.2,0.6l43.7,0.6c1.5,0,3.9,0.1,7.1,0.1l0.2,0
		c5.8,0.1,14.3,0.3,25.5,0.5C948.4,223,959.5,223.2,968,223.2z M839.9,64.8c-0.6-20.3-1-37.4-1-49.6c2,0,6.3,0.1,12.8,0.4
		c6.5,0.3,10.8,0.4,13.2,0.4c3.2,0,7.8-0.2,14.6-0.5c4.5-0.2,8.5-0.4,11.7-0.5c-1.1,17.5-2.4,43.8-4,82.8l-0.1,38.8
		c0,12.6,0,21.7,0.1,27.6l0.3,3.7c0.3,3.2,2.4,5.5,5.7,6.2l0.3,0.1l4.4,0c8.5,0,21.1-0.4,39.7-1.4c18.1-0.9,30.5-1.4,38.8-1.4
		c-0.1,16.2-1.2,29.7-3.6,41.3c-0.1,0.6-0.2,0.9-0.3,1.2l-0.2,0.1c-0.4,0.1-1.5,0.1-4.4,0.1c-8.4,0-19.4-0.2-32.7-0.6
		c-11.4-0.3-19.9-0.4-25.7-0.6c-3.2-0.1-5.5-0.1-7.1-0.1l-43.8-0.6l-0.1,0l-0.1,0c-4.2,0.1-10.3-0.1-18.2-0.6
		c-0.2,0-0.3-0.1-0.4-0.1c0-10.4,0.2-25.4,0.5-47.8c0.3-22.5,0.5-37.6,0.5-48C840.9,103.8,840.6,86.1,839.9,64.8z"/>
									<path className="st0" d="M489.7,320.7c-0.8-5.4-1-8.5-1-10.2c0-2.1,0.1-5.6,0.3-10.8c0.2-4.7,0.3-8.5,0.3-11.2l0-0.2
		c-0.1-2.3-0.4-4.7-2.2-6.1c-1.6-1.3-3.6-1.4-6.3-1.4c-0.3,0-0.3,0-19.6,1.5c-22.7,1.8-39.5,2.8-48.3,2.8c-31.3,0-54.1-1-69.7-3.1
		l-0.5,0c-0.7,0-1.1,0-1.4-0.1c-0.3,0-0.5,0-0.7,0c-6,0-7.3,7.8-8,15.1c-0.4,4.7-0.7,9.3-1,13.7c-0.2,1.3-0.9,5.2-2.5,12
		c-2,9.2-2.2,11.1-2.2,12.3c0,3,2.1,6,6.8,6c11.3,0,25.6-0.8,42.4-2.4c0.7,23.5,1.1,39,1.1,46c0,11.4-0.5,28.8-1.5,51.7
		c-1,23-1.5,40.6-1.5,52.1c0,4.7,2.9,8,7.9,8.9c5.2,0.8,14.4,1.3,27.2,1.3c11.4,0,20.3-0.4,26.4-1.2c3.5-0.4,4.7-0.6,5.8-1.3
		c2.3-1.3,3.5-3.8,3.5-7.4c0-11-0.4-27.6-1.4-52.3c-1-24.6-1.4-41.1-1.4-51.9c0-9.1,0.3-24.9,0.8-47c21.4,1.2,35.9,1.9,39.7,1.9
		c4.7,0,7.7-2.9,7.7-7.4c0-2.4-0.3-5.7-0.7-11L489.7,320.7z M434.2,327.9l-0.1,4.7c-0.6,24.6-0.9,42.1-0.9,51.9
		c0,11,0.4,27.6,1.4,52.3c0.9,24.2,1.4,40.6,1.4,51.5c-0.5,0.1-1,0.1-1.4,0.2l-0.1,0c-5.7,0.8-14.2,1.2-25.2,1.2
		c-12.4,0-21-0.4-25.7-1.1c-0.2,0-0.3-0.1-0.4-0.1c0-11.4,0.5-28.8,1.5-51.7c1-23.1,1.5-40.6,1.5-52.1c0-7.6-0.4-24.8-1.2-51.1
		l-0.1-4.8L380,329c-17.4,1.8-32,2.7-43.7,2.9c0.3-1.8,0.8-4.2,1.5-7.3c1.6-6.9,2.5-11.3,2.7-13l0-0.3c0.3-4.5,0.6-9.1,1-13.7
		c0.3-3.6,0.8-5.7,1.1-6.8c15.9,2.1,38.9,3.1,70.2,3.1c9.2,0,25.8-0.9,49.1-2.8c12.3-1,16.8-1.3,18.3-1.4c0,2.5-0.1,5.7-0.3,9.6
		c-0.2,5.4-0.3,8.9-0.3,11.2c0,2.8,0.4,6.7,1,11.3c0.3,3.2,0.5,6.3,0.6,8.5c-5.6-0.1-21.1-0.9-42.5-2.2L434.2,327.9z"/>
									<path className="st0" d="M600.8,331.8c-15.1,0-28,6.1-38.4,18.3c-9.9,11.5-15,25-15,40c0,16,4.7,29.6,14.1,40.4
		c10,11.4,23.2,17.2,39.3,17.2c15.1,0,28.1-6.1,38.6-18.2c9.8-11.3,14.8-24.7,14.8-39.7c0-15.7-4.8-29.3-14.4-40.3
		C629.7,337.8,616.6,331.8,600.8,331.8z M600.8,438.7c-13.5,0-24.2-4.6-32.5-14.2c-8-9.2-11.9-20.5-11.9-34.4
		c0-13,4.2-24.2,12.8-34.2c8.6-10,19.2-15.1,31.6-15.1c13.2,0,23.8,4.8,32.2,14.5c8.2,9.5,12.2,20.8,12.2,34.5
		c0,12.8-4.2,24.2-12.6,33.8C623.8,433.8,613.3,438.7,600.8,438.7z"/>
									<path className="st0" d="M600.7,277.1c-31.4,0-57.8,11.2-78.5,33.2c-20.3,21.5-30.5,48.3-30.5,79.8c0,31.5,10.3,58.2,30.5,79.5
		c20.7,22,47.1,33.1,78.5,33.1c31.6,0,58-11,78.7-32.8c20.2-21.3,30.4-48.2,30.4-79.8c0-31.1-10.3-57.9-30.7-79.8
		C658.2,288.3,631.8,277.1,600.7,277.1z M600.7,493.7c-28.8,0-53-10.2-71.9-30.3c-18.6-19.6-28.1-44.2-28.1-73.3
		c0-29.1,9.4-53.8,28.1-73.6c18.9-20.2,43.1-30.4,71.9-30.4c28.5,0,52.7,10.2,71.8,30.4c18.8,20.1,28.3,44.9,28.3,73.6
		c0,29.2-9.4,54-28,73.6C654,483.6,629.7,493.7,600.7,493.7z"/>
									<path className="st0" d="M897,282.6c-4.9,0-12.3,0.2-24.6,0.8c-12.1,0.6-19.4,0.8-24.2,0.8h-5.4l-0.7,0.2c-4.4,1.5-4.4,6.9-4.4,8.7
		c0,7.2,0.1,18.1,0.3,32.3c0.2,14.2,0.3,25,0.3,32.1c0,1.6-1.3,8.1-13.3,32.9c-5.3,10.9-9.9,20.1-14.2,28.1
		c-22.9-43.4-25.4-53.3-25.4-55.5c0-7.5,0.5-18.7,1.7-35.4c1.2-16.9,1.7-28.3,1.7-36c0-3.5-2.1-7.6-8.2-7.6
		c-6.5,0-15.9-0.2-26.6-0.7c-13.9-0.6-22.3-0.7-26.9-0.7c-1.7,0-7.4,0.7-7.4,9.9c0,7-0.2,17.3-0.8,34.7c-0.6,17.5-0.8,27.9-0.8,34.9
		c0,2.5,0,8.2,27,62.3c12.6,24.7,23.6,46.3,33.6,66.1c2,3.8,4.5,5.9,7.8,6.6c1.6,0.3,5.7,0.7,23.5,0.7c9.8,0,17.8-0.2,23.3-0.5
		l0.1,0c4.2-0.4,5.3-1.1,5.8-1.5l0.2-0.1c1.3-0.9,2.1-2.4,3.3-5.2c14-27.2,25.1-48.3,32.9-62.7l0.1-0.1c26-52.1,29.4-64.2,29.4-68.9
		c0-5.5,0.1-13.1,0.2-22.7l0-0.1c0-3.5,0.1-7.3,0.2-11.3c0.3-16.8,0.4-27.9,0.4-34.2C905.9,286.9,903.6,282.6,897,282.6z M810,488.9
		c-17.9,0-21.1-0.4-21.7-0.5c-0.4-0.1-1-0.8-1.6-1.9c-10-19.9-21-41.5-33.6-66.1c-24.8-49.7-26-57.3-26-58.3
		c0-6.9,0.2-17.3,0.8-34.7c0.6-17.4,0.8-27.9,0.8-34.9c0-0.4,0-0.6,0-0.9c4.7,0,12.6,0.2,24.9,0.7c13.1,0.5,21.4,0.7,26.2,0.7
		c-0.1,7.5-0.6,18.3-1.7,34c-1.2,16.9-1.7,28.3-1.7,36c0,4.8,3.4,16.7,30.3,67.1l3.9,7.3l4-7.2c5.4-9.6,11.4-21.4,18.4-35.8
		c12.6-26.1,14.2-33.4,14.2-36.9c0-7.2-0.1-18.1-0.3-32.3c-0.2-14.1-0.3-24.9-0.3-32h1.5c5,0,12.3-0.2,24.6-0.8
		c12.1-0.6,19.3-0.8,24.1-0.8c0,6.4-0.1,17.1-0.4,33c-0.1,4-0.1,7.8-0.2,11.3l0,0.2c-0.1,9.6-0.2,17.2-0.2,22.8
		c0,3-3.7,15.4-28.4,64.8c-7.9,14.4-19,35.5-33,62.9l-0.1,0.2c-0.3,0.7-0.5,1.2-0.7,1.5c-0.3,0-0.7,0.1-1.2,0.1
		C829,488.7,822.1,488.9,810,488.9z"/>
									<path className="st0" d="M978.3,282.4l-0.4,0c-2.8,0-7.1,0.2-12.6,0.6c-5.4,0.4-9.4,0.6-12,0.6s-6.6-0.2-12-0.6
		c-5.6-0.4-9.8-0.6-12.6-0.6c-1.7,0-7.1,0.7-7.1,9.9c0,3.7,0.1,10.2,0.4,20c0.3,9.7,0.4,16,0.4,19.6c0,3.9-0.2,9.9-0.6,17.7
		c-0.4,7.9-0.6,14-0.6,18.1c0,3.3,1.6,12.5,14,54.8c2.3,7.9,13.8,7.9,17.5,7.9c9.2,0,14.6-3,16.1-8.9c3.9-16.2,7.8-32.3,11.6-47.9
		l0-0.1c3.2-15.8,4.9-33,4.9-51c0-11.8-0.3-20.9-0.8-26.9C984,288.4,983.1,282.9,978.3,282.4z M960,419.4c-0.2,0.9-2.3,2-7.3,2
		c-6.2,0-8.5-1.1-8.9-1.5c-11.8-40.4-13.6-50-13.6-52.2c0-3.9,0.2-9.9,0.6-17.7c0.4-7.9,0.6-14,0.6-18.1c0-3.7-0.1-10.2-0.4-20
		c-0.3-9.7-0.4-16-0.4-19.6c0-0.3,0-0.6,0-0.9c2.5,0.1,5.9,0.3,10,0.6c5.6,0.4,9.8,0.6,12.6,0.6s7.1-0.2,12.6-0.6
		c3.7-0.3,6.7-0.4,9.1-0.5c0.6,3.3,1.3,11.3,1.3,31c0,17.4-1.6,33.9-4.7,49.1C967.8,387.2,963.9,403.3,960,419.4z"/>
									<path className="st0" d="M953.2,435.2c-9.4,0-17.2,3.1-23.1,9.2c-5.9,6-8.9,13.9-8.9,23.4c0,9.3,3,17.3,8.8,23.6
		c5.9,6.3,13.7,9.5,23.3,9.5c9.4,0,17.2-3.2,23.2-9.5c5.8-6.3,8.8-14.2,8.8-23.6c0-9.4-3-17.3-9-23.4
		C970.3,438.3,962.5,435.2,953.2,435.2z M969.9,485.2c-4.3,4.5-9.7,6.7-16.6,6.7c-7.1,0-12.6-2.2-16.7-6.7
		c-4.3-4.7-6.4-10.4-6.4-17.4c0-7,2.1-12.8,6.3-17.1c4.2-4.4,9.7-6.5,16.7-6.5c6.9,0,12.4,2.1,16.7,6.6c4.3,4.4,6.4,10,6.4,17
		C976.3,474.9,974.2,480.6,969.9,485.2z"/>
								</g>
								<path className="st1" d="M227.1,211.6c0,3-1.4,4.5-4.3,4.5H171c-2.8,0-4.3-1.7-4.3-5.1c0-9.9,0.4-24.7,1.1-44.4
	c0.7-19.7,1.1-34.6,1.1-44.4c0-2.8-0.4-4.3-1.3-4.5c-1.1,0.6-7.6,15.7-19.6,45.3c-7,17.4-14.1,34.8-21.1,52.1c-1,2.2-2.2,3.6-3.7,4
	c-0.5,0.1-2.4,0.1-5.9,0.1c-5.1,0-8.1-1-9.1-2.9c-5.3-10.8-12.2-26.4-20.8-46.9c-9.2-21.7-16.1-37.4-20.7-47.1
	c-0.2-0.5-0.6-1-1.3-1.6c-0.7,0.9-1,2.3-1,4.3c0,9.6,0.2,24,0.7,43.1c0.5,19.2,0.7,33.5,0.7,43.1c0,3.4-1.4,5.1-4.1,5.1h-49
	c-2,0-3.2-0.3-3.6-1c-0.4-0.6-0.7-1.7-0.7-3.3c0-10.7,0.5-26.7,1.5-48c1-21.4,1.5-37.4,1.5-48c0-11.3-0.5-32.6-1.5-63.8
	C10,44,9.6,31.9,8.8,15.9l-0.2-2.5c-0.1-2,2-2.9,6.2-2.9c5.4,0,13.4-0.2,24.2-0.5c10.8-0.3,18.8-0.5,24.2-0.5c1.1,0,2.6,2.1,4.5,6.2
	l42.8,94c3,6.4,5.6,9.5,7.6,9.5c1.1,0,3.4-3.7,6.9-11c9.9-20.5,24.5-51.5,43.8-92.8c1.7-3.8,3.5-5.7,5.4-5.7c2.8,0,7.1,0.1,12.8,0.4
	c5.7,0.3,10,0.4,13,0.4c2.4,0,6.2-0.2,11.2-0.7s8.8-0.7,11.2-0.7c2.9,0,4.4,1.2,4.4,3.5c0,11.5-0.4,28.8-1.2,51.7
	s-1.2,40.2-1.2,51.7c0,10.7,0.4,26.6,1.3,47.9C226.7,185.2,227.1,201.1,227.1,211.6z"/>
								<path className="st1" d="M447.8,212.5c0,1.8-1.2,3-3.7,3.7c-2.9,0.8-12,1.2-27.3,1.2c-14,0-23-0.4-27-1.2c-3.3-0.7-5.4-2.9-6.3-6.6
	c-3.3-14.1-5.9-21.6-7.8-22.6c-1.6-0.7-14.7-1-39.3-1c-16.6,0-25.5,0.4-26.5,1.3c-1.7,1.6-3.5,6.8-5.6,15.7
	c-1.9,8.1-4.1,12.5-6.7,13.2c-3.3,0.8-14.5,1.2-33.4,1.2c-15,0-22.4-1.4-22.4-4.3c0-2.5,11-33.8,33.1-93.7
	c23.9-64.8,36.9-100.4,39-106.9c1.2-3.7,2.5-5.6,4.1-5.6c2.6,0,6.7,0.2,12.2,0.7s9.7,0.7,12.4,0.7c2.6,0,6.5-0.2,11.7-0.5
	c5.1-0.3,9-0.5,11.5-0.5c1.9,0,3.6,1.6,5.1,4.8c4.8,10.3,18.8,45.5,41.9,105.6C436.2,178.3,447.8,209.9,447.8,212.5z M343.8,78.9
	c-2.1,4.3-5.5,13.6-10.4,28c-5.3,15.2-7.9,24.2-7.9,26.8c0,1.8,2.9,2.7,8.8,2.9c3,0.2,8,0.3,15,0.3c6.5,0,10-0.8,10.7-2.3
	c0.2-0.5,0.3-1.2,0.3-2.1c0-3.2-2.2-12.1-6.7-26.7C349.1,91.8,345.9,82.9,343.8,78.9z"/>
								<path className="st1" d="M594.9,218.5c-5.8,0-14.4-0.3-26-0.9c-11.5-0.6-20.2-0.9-26-0.9c-8.2,0-20.5-0.1-36.8-0.3s-28.6-0.3-36.8-0.3
	c-3.5,0-5.5-1.1-6-3.4c-0.2-6.3-1.2-15.5-2.9-27.9c-1.2-4.8-1.8-7.5-1.8-8.1c0-0.5,12-19.4,35.9-56.8c24-37.2,36-57.3,36-60.2
	c0-1.6-1.3-2.3-3.8-2.3c-6.8,0-17.2,0.2-31,0.6c-13.8,0.4-24.2,0.6-31.2,0.6c-2.2,0-3.2-1.1-3.2-3.4c0-3.6,1.1-10.7,3.3-21.1
	c2.2-10.5,3.3-18,3.3-22.7c0-1.6,1.3-2.3,4-2.3c13.5,0,33.7,0.3,60.8,0.9c27,0.6,47.3,0.9,60.8,0.9c2.1,0,3.4,1.1,4,3.4
	c1.8,7.8,2.6,19,2.6,33.6c0,1.8-0.9,4-2.6,6.6c-6.2,8.9-19.1,28.5-38.9,58.9c-22.5,34.7-33.7,52.9-33.7,54.5c0,0.8,0.8,1.2,2.5,1.2
	c19.8,0,35.1-0.4,45.7-1.2c2.1-0.1,6.8-0.7,14.1-1.8c6.2-1,10.7-1.5,13.6-1.5c1.4,0,2.1,1,2.1,3.1c0,15.9-1.4,31.8-4.3,47.7
	C598.1,217.5,596.9,218.5,594.9,218.5z"/>
								<path className="st1" d="M818.3,212.5c0,1.8-1.2,3-3.7,3.7c-2.9,0.8-12,1.2-27.3,1.2c-14,0-23-0.4-27-1.2c-3.3-0.7-5.4-2.9-6.3-6.6
	c-3.3-14.1-5.9-21.6-7.8-22.6c-1.6-0.7-14.7-1-39.3-1c-16.6,0-25.5,0.4-26.5,1.3c-1.7,1.6-3.5,6.8-5.6,15.7
	c-1.9,8.1-4.1,12.5-6.7,13.2c-3.3,0.8-14.5,1.2-33.4,1.2c-15,0-22.4-1.4-22.4-4.3c0-2.5,11-33.8,33.1-93.7
	c23.9-64.8,37-100.4,39-106.9c1.2-3.7,2.5-5.6,4.1-5.6c2.6,0,6.7,0.2,12.2,0.7s9.7,0.7,12.4,0.7c2.6,0,6.5-0.2,11.7-0.5
	c5.1-0.3,9-0.5,11.5-0.5c1.9,0,3.6,1.6,5.1,4.8c4.8,10.3,18.8,45.5,41.9,105.6C806.7,178.3,818.3,209.9,818.3,212.5z M714.4,78.9
	c-2.1,4.3-5.5,13.6-10.4,28c-5.3,15.2-7.9,24.2-7.9,26.8c0,1.8,2.9,2.7,8.8,2.9c3,0.2,8,0.3,15,0.3c6.5,0,10-0.8,10.7-2.3
	c0.2-0.5,0.3-1.2,0.3-2.1c0-3.2-2.2-12.1-6.7-26.7C719.6,91.8,716.4,82.9,714.4,78.9z"/>
								<path className="st1" d="M968,218.7c-8.4,0-19.4-0.2-32.8-0.6c-17.6-0.4-28.5-0.6-32.8-0.7l-43.8-0.6c-4.4,0.1-10.7-0.1-18.9-0.6
	c-2.8-0.5-4.3-2-4.3-4.5c0-10.7,0.2-26.6,0.5-47.9s0.5-37.2,0.5-47.9c0-11.3-0.3-28.3-1-51c-0.7-22.6-1-39.6-1-51
	c0-2.1,1.5-3.2,4.4-3.2s7.3,0.1,13.1,0.4c5.8,0.3,10.1,0.4,13,0.4c3.2,0,8-0.2,14.4-0.5s11.1-0.5,14.4-0.5c1.5,0,2.1,0.9,2.1,2.8
	l-0.1,1.9c-1.2,18.4-2.5,46-4,82.8l-0.1,38.7c0,12.5,0,21.7,0.1,27.4c0.1,1.2,0.2,2.3,0.3,3.5s0.8,1.9,2.2,2.2c0.1,0,1.4,0,3.8,0
	c8.8,0,22-0.5,39.5-1.4s30.7-1.4,39.5-1.4c2.6,0,4,1.1,4,3.4c0,16.7-1.2,31.1-3.7,43.3c-0.5,2.5-1.5,4.1-3.1,4.5
	C973.5,218.6,971.5,218.7,968,218.7z"/>
								<path className="st1" d="M485.9,331.9c0,2-1.1,2.9-3.2,2.9c-3.7,0-18.4-0.7-44.1-2.2c-0.6,24.7-0.9,42-0.9,51.8
	c0,11.5,0.5,28.9,1.4,52.1s1.4,40.5,1.4,52.1c0,1.9-0.4,3-1.3,3.5c-0.3,0.2-1.7,0.4-4.1,0.7c-5.9,0.8-14.5,1.2-25.8,1.2
	c-12.6,0-21.5-0.4-26.5-1.2c-2.7-0.5-4.1-2-4.1-4.5c0-11.5,0.5-28.8,1.5-51.9s1.5-40.4,1.5-51.9c0-7.6-0.4-24.6-1.2-51
	c-19.6,2-35.1,2.9-46.8,2.9c-1.6,0-2.3-0.5-2.3-1.5c0-1.3,0.7-5,2.1-11.3c1.6-6.8,2.4-11,2.6-12.5c0.3-4.6,0.6-9.2,1-13.8
	c0.7-7.3,1.9-11,3.5-11c0.2,0,0.9,0.1,2.1,0.1c15.8,2.1,39.2,3.1,70.2,3.1c8.8,0,25-0.9,48.7-2.8c12.7-1,19.1-1.5,19.2-1.5
	c2,0,3.1,0.1,3.4,0.4c0.3,0.2,0.5,1.2,0.6,2.9c0,2.6-0.1,6.3-0.3,11c-0.2,5.2-0.3,8.8-0.3,11c0,2.4,0.3,6,1,10.8
	C485.6,326,485.9,329.6,485.9,331.9z"/>
								<path className="st1" d="M705.3,390.1c0,30.5-9.7,56.1-29.2,76.7c-19.8,20.9-45,31.4-75.4,31.4c-30.2,0-55.3-10.6-75.2-31.7
	c-19.6-20.6-29.3-46.1-29.3-76.4c0-30.4,9.8-56,29.3-76.7c19.9-21.2,45-31.8,75.2-31.8c30,0,55,10.6,75.1,31.8
	C695.4,334.4,705.3,359.9,705.3,390.1z M649.7,389.8c0-14.7-4.4-27.1-13.3-37.4c-9.3-10.8-21.2-16.1-35.6-16.1
	c-13.8,0-25.5,5.6-35,16.7c-9.3,10.8-13.9,23.1-13.9,37.1c0,15,4.3,27.4,13,37.4c9.2,10.5,21.2,15.7,35.9,15.7
	c13.8,0,25.5-5.5,35.2-16.6C645.1,416.1,649.7,403.8,649.7,389.8z"/>
								<path className="st1" d="M810,493.4c-13.2,0-20.7-0.2-22.6-0.6c-1.9-0.4-3.4-1.8-4.7-4.3c-11.1-22-22.3-44-33.6-66.1
	c-17.7-35.5-26.5-55.6-26.5-60.3c0-7.7,0.3-19.3,0.8-34.8s0.8-27.1,0.8-34.8c0-3.6,1-5.4,2.9-5.4c5.9,0,14.8,0.2,26.7,0.7
	s20.9,0.7,26.8,0.7c2.4,0,3.7,1,3.7,3.1c0,7.9-0.6,19.8-1.7,35.7c-1.1,15.9-1.7,27.8-1.7,35.7c0,6.1,9.9,27.7,29.8,65
	c5.4-9.7,11.5-21.6,18.3-35.6c9.2-19,13.8-30.6,13.8-34.9c0-7.1-0.1-17.9-0.3-32.2c-0.2-14.3-0.3-25-0.3-32.2c0-2.6,0.4-4.1,1.3-4.4
	h4.7c5.4,0,13.5-0.3,24.4-0.8s19-0.8,24.4-0.8c2.9,0,4.4,1.2,4.4,3.5c0,7.5-0.1,18.9-0.4,34.1c-0.2,15.2-0.4,26.6-0.4,34.2
	c0,6.1-9.6,28.3-28.9,66.9c-7.6,13.9-18.6,34.8-33,62.8c-1,2.2-1.6,3.3-2,3.6c-0.3,0.2-1.6,0.5-3.7,0.7
	C828.1,493.2,820.4,493.4,810,493.4z"/>
								<path className="st1" d="M980.8,322.5c0,17.8-1.6,34.5-4.8,50.1c-3.9,16-7.8,32-11.6,47.9c-0.9,3.6-4.8,5.4-11.7,5.4
	c-7.9,0-12.3-1.6-13.2-4.7c-9.2-31.4-13.8-49.2-13.8-53.5c0-4,0.2-10,0.6-17.9c0.4-7.9,0.6-13.9,0.6-17.9c0-4.4-0.1-11-0.4-19.8
	c-0.3-8.8-0.4-15.4-0.4-19.8c0-3.6,0.9-5.4,2.6-5.4c2.7,0,6.8,0.2,12.3,0.6s9.6,0.6,12.3,0.6s6.8-0.2,12.3-0.6s9.6-0.6,12.3-0.6
	C979.8,287.1,980.8,298.9,980.8,322.5z M980.8,467.8c0,8.2-2.5,15-7.6,20.5c-5.1,5.4-11.7,8.1-19.9,8.1c-8.3,0-15-2.7-20-8.1
	s-7.6-12.2-7.6-20.5c0-8.2,2.5-15,7.6-20.2c5.1-5.3,11.7-7.9,19.9-7.9c8.1,0,14.7,2.6,19.9,7.9S980.8,459.6,980.8,467.8z"/>
							</svg></Link>
					</div>
					<div className='link button' onClick={() => {
						document.querySelector(".menu").classList.add("showMenu");
					}}>MENU</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
